













import Vue from 'vue'
import store from "../../store/shop";
export default Vue.component('SHNavMenu', {
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  props: {
      isShow: {
          type: Boolean,
          required: true,
      },
  }
})
